import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Select, Form, Input } from 'semantic-ui-react'
import { append, countryOptions, preventDefault } from '../helpers'
import ProductButton from './product_button'
import PhoneInput from './phone_input'

export const defaultAddress = {
	name: '',
	contactName: '',
	address1: '',
	address2: '',
	city: '',
	postalCode: '',
	state: '',
	note: '',
	reference: '',
	countryCode: '',
	countryName: '',
	email: '',
	mobile: '',
}

export function formatAddress(address = defaultAddress, separator = ', ') {
	const parts = [
		address.address1,
		address.address2,
		[address.postalCode, address.city].filter(item => typeof item === 'string').map(item => item.trim()).filter(item => item.length > 0).join(' '),
		address.countryName
	]
	return parts.filter(item => typeof item === 'string').map(item => item.trim()).filter(item => item.length > 0).join(separator)
}

export default function AddressInput({
	compact,
	address,
	cityLoading,
	onChange,
}) {
	compact = compact ?? false
	address = address ?? defaultAddress
	cityLoading = cityLoading ?? false
	onChange = onChange ?? ((address, changedField) => {})

	const lastAddress = useRef({...defaultAddress})
	const [compactShowAddressInput, setCompactShowAddressInput] = useState(false)

	const nameEmpty = typeof address.name !== 'string' || address.name.trim().length === 0
	const referenceEmpty = typeof address.reference !== 'string' || address.reference.trim().length === 0
	const noteEmpty = typeof address.note !== 'string' || address.note.trim().length === 0
	const contactNameEmpty = typeof address.contactName !== 'string' || address.contactName.trim().length === 0
	const address1Empty = typeof address.address1 !== 'string' || address.address1.trim().length === 0
	const postalCodeEmpty = typeof address.postalCode !== 'string' || address.postalCode.trim().length === 0
	const cityEmpty = typeof address.city !== 'string' || address.city.trim().length === 0
	const mobileEmpty = typeof address.mobile !== 'string' || address.mobile.trim().length === 0
	const emailEmpty = typeof address.email !== 'string' || address.email.trim().length === 0
	const mobileAndEmailEmpty = mobileEmpty && emailEmpty
	const hasErrors = nameEmpty || address1Empty || postalCodeEmpty || cityEmpty || mobileAndEmailEmpty

	const fallback = useMemo((value, fallback = '') => typeof value !== 'string' ? fallback : value, [])

	useEffect(() => {
		if (compact && !cityLoading && hasErrors) {
			setCompactShowAddressInput(true)
		} else {
			let changedFields = 0;
			for (let key in address) {
				if (address[key] !== lastAddress.current[key]) {
					changedFields++;
				}
			}
			if (changedFields > 1) {
				setCompactShowAddressInput(false)
			}
		}
		lastAddress.current = {...address}
	}, [compact, address, cityLoading, hasErrors])

	let elems = []

	if (compact) {
		elems.push(<ProductButton
			key="toggleAddressInput"
			labelPosition="top"
			label={compactShowAddressInput ? "Trykk for å slutte å endre adressen" : "Trykk for å endre adressen"}
			active={compactShowAddressInput}
			onClick={preventDefault(() => setCompactShowAddressInput(hasErrors ? true : !compactShowAddressInput))}
		>
			{!compactShowAddressInput ? <p>
				{!nameEmpty ? address.name : ''}<br/>
				{formatAddress(address)}<br/>
				{!contactNameEmpty || !mobileEmpty || !emailEmpty || !referenceEmpty || !noteEmpty ? <br/> : null}
				{!contactNameEmpty ? <>Kontaktperson: {address.contactName}<br/></> : null}
				{!mobileEmpty ? <>Telefonnummer: {address.mobile}<br/></> : null}
				{!emailEmpty ? <>E-post: {address.email}<br/></> : null}
				{!referenceEmpty ? <>Referanse: {address.reference}<br/></> : null}
				{!noteEmpty ? <>Notat: {address.note}<br/></> : null}
			</p> : <p>...</p> }
		</ProductButton>)
		elems.push(<p key="addressInputSpacer"></p>)
	}

	if (!compact || compactShowAddressInput) elems.push(<React.Fragment key="addressInputForm">
		<Form.Group widths="equal">
			<Form.Field required error={nameEmpty}>
				<label>Navn</label>
				<Input
					type="text"
					placeholder="Eksempelfirma AS"
					value={fallback(address.name, '')}
					onChange={(e, data) => {
						const name = data.value
						onChange(append(address, { name }), 'name')
					}}
				/>
			</Form.Field>
			<Form.Field>
				<label>Kontaktperson</label>
				<Input
					type="text"
					placeholder="Ola Nordmann"
					value={fallback(address.contactName, '')}
					onChange={(e, data) => {
						const contactName = data.value
						onChange(append(address, { contactName }), 'contactName')
					}}
				/>
			</Form.Field>
		</Form.Group>
		<Form.Group widths="equal">
			<Form.Field required error={address1Empty}>
				<label>Adresse (linje 1)</label>
				<Input
					type="text"
					placeholder="Eksempelveien 1"
					maxLength="35"
					pattern="^[ -~æøåÆØÅ]+$"
					value={fallback(address.address1, '')}
					onChange={(e, data) => {
						const address1 = data.value
						onChange(append(address, { address1 }), 'address1')
					}}
				/>
			</Form.Field>
			<Form.Field>
				<label>Adresse (linje 2)</label>
				<Input
					type="text"
					placeholder="For eksempel bruksnummer (H0101)"
					maxLength="35"
					pattern="^[ -~æøåÆØÅ]+$"
					value={fallback(address.address2, '')}
					onChange={(e, data) => {
						const address2 = data.value
						onChange(append(address, { address2 }), 'address2')
					}}
				/>
			</Form.Field>
		</Form.Group>
		<Form.Group>
			<Form.Field width={3} required error={postalCodeEmpty}>
				<label>Postnummer</label>
				<Input
					type="text"
					placeholder="0000"
					maxLength="35"
					pattern="^[ -~æøåÆØÅ]+$"
					value={fallback(address.postalCode, '')}
					onChange={(e, data) => {
						const postalCode = data.value
						onChange(append(address, { postalCode }), 'postalCode')
					}}
				/>
			</Form.Field>
			<Form.Field width={13} required error={cityEmpty}>
				<label>Poststed</label>
				<Input
					type="text"
					placeholder="Eksempelbyen"
					maxLength="35"
					pattern="^[ -~æøåÆØÅ]+$"
					loading={cityLoading}
					disabled={cityLoading}
					value={fallback(address.city, '')}
					onChange={(e, data) => {
						const city = data.value
						onChange(append(address, { city }), 'city')
					}}
				/>
			</Form.Field>
		</Form.Group>
		<Form.Field required>
			<label>Land</label>
			<Select
				search
				options={countryOptions.no}
				value={fallback(address.countryCode, '')}
				onChange={(_, data) => {
					const option = data.options.find(option => option.value === data.value)
					if (!option) return
					const countryCode = option.value
					const countryName = option.text
					onChange(append(address, { countryCode, countryName }), 'countryCode')
				}}
			/>
		</Form.Field>
		<div><Form.Field label="Mobil og/eller e-post" required /></div>
		<Form.Group widths="equal">
			<Form.Field error={mobileAndEmailEmpty}>
				<label><small>Telefonnummer</small></label>
				<PhoneInput
					value={fallback(address.mobile, '')}
					onChange={mobile => {
						onChange(append(address, { mobile }), 'mobile')
					}}
				/>
			</Form.Field>
			<Form.Field error={mobileAndEmailEmpty}>
				<label><small>E-post</small></label>
				<Input
					type="email"
					placeholder="ola.nordmann@example.com"
					maxLength="35"
					value={fallback(address.email, '')}
					onChange={(e, data) => {
						const email = data.value
						onChange(append(address, { email }), 'email')
					}}
				/>
			</Form.Field>
		</Form.Group>
		<Form.Group widths="equal">
			<Form.Field>
				<label>Referanse</label>
				<Input
					placeholder="Oppgi en referanse"
					maxLength="35"
					pattern="^[ -~æøåÆØÅ]+$"
					value={fallback(address.reference, '')}
					onChange={(e, data) => {
						const reference = data.value
						onChange(append(address, { reference }), 'reference')
					}}
				/>
			</Form.Field>
			<Form.Field>
				<label>Notat</label>
				<Input
					placeholder="Oppgi ekstra informasjon om adressen, hvis nødvendig"
					maxLength="35"
					pattern="^[ -~æøåÆØÅ]+$"
					value={fallback(address.note, '')}
					onChange={(e, data) => {
						const note = data.value
						onChange(append(address, { note }), 'note')
					}}
				/>
			</Form.Field>
		</Form.Group>
	</React.Fragment>)

	return elems
}