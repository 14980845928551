import React, { useCallback, useMemo, useState } from 'react'
import { Segment, Header, Container, Button, Icon, Grid } from 'semantic-ui-react'
import DataTable from '../../components/data_table'
import { getAccounts } from '../../api/accounts'
import { filterTypes, formatNumber, handleRedirect, navigate, preventDefault } from '../../helpers'
import Link from '../../components/link'
import { useNavigate } from 'react-router-dom'
import { useAuth, usePreloadedEntityDispatcher } from '../root'

export default function AccountsPage() {
	const { user } = useAuth()
	const [dataInfo, setDataInfo] = useState(null)
	const routerNavigateFn = useNavigate()
	const preloadEntity = usePreloadedEntityDispatcher()

	const defaultQuery = useMemo(() => ({
		op: 'and',
		grp: [
			{key: 'parent.id', op: 'eq', val: user.account.id},
			{
				op: 'or',
				grp: [
					{key: 'is_customer', op: 'eq', val: true},
					{key: 'is_supplier', op: 'eq', val: true},
				]
			}
		]
	}), [user])

	const apiSearch = useCallback(async (query, options, requestOptions) => {
		const res = await getAccounts(query, options, requestOptions)
		handleRedirect(res, routerNavigateFn)
		if (JSON.stringify(query) === JSON.stringify(defaultQuery)) {
			setDataInfo(res.data_info)
		}
		return res
	}, [defaultQuery, routerNavigateFn])

	return <>
		<Segment vertical padded="very">
			<Container>
				<Grid stackable>
					<Grid.Row>
						<Grid.Column width={10}>
							<Header size="huge">
								Kunder og leverandører
								<Header.Subheader>Liste over kunder og leverandører du kan administrere</Header.Subheader>
							</Header>
						</Grid.Column>
						<Grid.Column width={6} textAlign="right">
							<Button
								as="a"
								href="/accounts/new"
								onClick={preventDefault(e => navigate('/accounts/new', (e.ctrlKey || e.shiftKey) ? '_blank' : '_self', {}, routerNavigateFn))}
								color="orange"
								label={dataInfo ? formatNumber(dataInfo.count) : '0'}
								labelPosition="right"
								disabled
								content={<><Icon name="add" /> Opprett ny kunde/leverandør</>}
							/>
						</Grid.Column>
					</Grid.Row>
				</Grid>
				<p></p>

				<DataTable
					columns={[
						{ key: 1, text: '#', visible: true },
						{ key: 'is_customer', text: 'Kunde', filterType: filterTypes.booleanQuery, filterQuery: [{op: 'eq', key: 'is_customer', val: false}, {op: 'eq', key: 'is_customer', val: true}] },
						{ key: 'customer_no', text: 'Kundenummer', searchable: true, filterType: filterTypes.search },
						{ key: 'is_supplier', text: 'Leverandør', filterType: filterTypes.booleanQuery, filterQuery: [{op: 'eq', key: 'is_supplier', val: false}, {op: 'eq', key: 'is_supplier', val: true}] },
						{ key: 'supplier_no', text: 'Vendor Number', searchable: true, filterType: filterTypes.search },
						{ key: 'name', text: 'Navn', visible: true, sortable: true, searchable: true, filterType: filterTypes.search },
						{ key: 'email', text: 'E-post', visible: true, sortable: true, searchable: true, filterType: filterTypes.search },
						{ key: 'phone.number', text: 'Telefon', visible: true, sortable: true, searchable: true, filterType: filterTypes.search },
						// { key: 3, text: '', visible: true },
					]}
					defaultOrder={{name: 'ASC'}}
					query={defaultQuery}
					apiSearch={apiSearch}
					onClickRow={(data, index, { ctrlKey, shiftKey }) => {
						// preload account data
						preloadEntity(data)
						// navigate to account
						const url = '/accounts/' + encodeURIComponent(data.id)
						const target = (ctrlKey || shiftKey) ? '_blank' : '_self'
						navigate(url, target, {}, routerNavigateFn)
					}}
					renderRow={data => [
						data.is_customer ? data.customer_no : (data.is_supplier ? data.supplier_no : null),
						data.name,
						<Link href={'mailto:' + data.email} target="_blank">{data.email}</Link>,
						<Link href={data.phone.uri} target="_blank">{data.phone.number}</Link>,
						// <div className="text-ha-right">
						// 	<Button.Group size="mini" compact>
						// 		<Popup
						// 			inverted
						// 			content="Opprett bruker"
						// 			trigger={<Button
						// 				as="a"
						// 				color="teal"
						// 				icon="user add"
						// 				href={'/accounts/' + encodeURIComponent(data.id) + '/users/new'}
						// 			/>}
						// 		/>
						// 		<Popup
						// 			inverted
						// 			content="Endre opplysninger"
						// 			trigger={<Button
						// 				as="a"
						// 				color="blue"
						// 				icon="edit"
						// 				href={'/accounts/' + encodeURIComponent(data.id) + '/edit'}
						// 			/>}
						// 		/>
						// 		<Popup
						// 			inverted
						// 			content="Slett kunde/leverandør"
						// 			trigger={<Button
						// 				as="a"
						// 				negative
						// 				icon="delete"
						// 				href={'/accounts/' + encodeURIComponent(data.id) + '/delete'}
						// 			/>}
						// 		/>
						// 	</Button.Group>
						// </div>
					]}
				/>
			</Container>
		</Segment>
	</>
}