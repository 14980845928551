import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Segment, Container, Header, Icon, Label, Message, Form, Grid } from 'semantic-ui-react'
import { append, debounce, isObject, omitEmpty, preventDefault, updateQueryParams } from '../../helpers'
import AssetLabel from '../../components/asset_label'
import { useLoaderData } from 'react-router-dom'
import Dash from '../../components/dash'
import { getConfigurationItem, TYPE_WORKSTATION, TYPE_PC_SHARED, TYPE_PC_MEETING_ROOM, TYPE_PC_PERSONAL, TYPE_SERVER, TYPE_PRINTER, TYPE_SWITCH, TYPE_FIREWALL, TYPE_ACCESS_POINT, TYPE_UPS, TYPE_DIGITAL_SIGNAGE, TYPE_TABLET, TYPE_MOBILE_PHONE } from '../../api/autotask/configuration_items'

const ASSET_TYPE_OTHER = ''
const ASSET_TYPE_PC = 'pc'
const ASSET_TYPE_SERVER = 'srv'
const ASSET_TYPE_PRINTER = 'prn'
const ASSET_TYPE_SWITCH = 'sw'
const ASSET_TYPE_GATEWAY = 'gw'
const ASSET_TYPE_ACCESS_POINT = 'ap'
const ASSET_TYPE_UPS = 'ups'
const ASSET_TYPE_DIGITAL_SIGNAGE = 'ds'
const ASSET_TYPE_TABLET = 'tab'
const ASSET_TYPE_MOBILE_PHONE = 'mob'

const ASSET_TYPE_SLUG = {
	[ASSET_TYPE_OTHER]: '',
	[ASSET_TYPE_PC]: 'PC',
	[ASSET_TYPE_SERVER]: 'SRV',
	[ASSET_TYPE_PRINTER]: 'PRINT',
	[ASSET_TYPE_SWITCH]: 'SW',
	[ASSET_TYPE_GATEWAY]: 'GW',
	[ASSET_TYPE_ACCESS_POINT]: 'AP',
	[ASSET_TYPE_UPS]: 'UPS',
	[ASSET_TYPE_DIGITAL_SIGNAGE]: 'DS',
	[ASSET_TYPE_TABLET]: 'TAB',
	[ASSET_TYPE_MOBILE_PHONE]: 'MOB',
}

const ASSET_TYPE_DISPLAY_NAME = {
	[ASSET_TYPE_OTHER]: 'Annen enhetstype',
	[ASSET_TYPE_PC]: 'PC',
	[ASSET_TYPE_SERVER]: 'Server',
	[ASSET_TYPE_PRINTER]: 'Printer',
	[ASSET_TYPE_SWITCH]: 'Switch',
	[ASSET_TYPE_GATEWAY]: 'Ruter',
	[ASSET_TYPE_ACCESS_POINT]: 'Aksesspunkt',
	[ASSET_TYPE_UPS]: 'UPS',
	[ASSET_TYPE_DIGITAL_SIGNAGE]: 'Infoskjerm',
	[ASSET_TYPE_TABLET]: 'Nettbrett',
	[ASSET_TYPE_MOBILE_PHONE]: 'Mobiltelefon',
}

const ASSET_TYPE_BY_CI_TYPE = {
	[TYPE_WORKSTATION]: ASSET_TYPE_PC,
	[TYPE_PC_SHARED]: ASSET_TYPE_PC,
	[TYPE_PC_MEETING_ROOM]: ASSET_TYPE_PC,
	[TYPE_PC_PERSONAL]: ASSET_TYPE_PC,
	[TYPE_SERVER]: ASSET_TYPE_SERVER,
	[TYPE_PRINTER]: ASSET_TYPE_PRINTER,
	[TYPE_SWITCH]: ASSET_TYPE_SWITCH,
	[TYPE_FIREWALL]: ASSET_TYPE_GATEWAY,
	[TYPE_ACCESS_POINT]: ASSET_TYPE_ACCESS_POINT,
	[TYPE_UPS]: ASSET_TYPE_UPS,
	[TYPE_DIGITAL_SIGNAGE]: ASSET_TYPE_DIGITAL_SIGNAGE,
	[TYPE_TABLET]: ASSET_TYPE_TABLET,
	[TYPE_MOBILE_PHONE]: ASSET_TYPE_MOBILE_PHONE,
}

const defaultType = ASSET_TYPE_PC
const generateName = (prefix, type, serial) => prefix.toString() + '-' + type.toString() + serial.toString()
const defaultName = generateName('EKSEMPEL', ASSET_TYPE_SLUG[defaultType], 1)
const defaultQueryParams = {
	tp: defaultType,
	nm: defaultName,
	sn: '',
	mn: '',
	ip: '',
	ma: '',
	ci: '',
}

const fallback = (value, fallback) => typeof value !== 'string' ? fallback : value
const fallbackEmpty = (value, fallback) => typeof value !== 'string' || value.length === 0 ? fallback : value

// /tools/generate-label?name=PC-NAVN&meta1=SN&meta2=MN
export async function loader({ request }) {
	const { searchParams } = new URL(request.url)

	return {
		tp: fallback(searchParams.get('tp'), defaultQueryParams.tp),
		nm: fallback(searchParams.get('nm'), defaultQueryParams.nm),
		sn: fallback(searchParams.get('sn'), defaultQueryParams.sn),
		mn: fallback(searchParams.get('mn'), defaultQueryParams.mn),
		ip: fallback(searchParams.get('ip'), defaultQueryParams.ip),
		ma: fallback(searchParams.get('ma'), defaultQueryParams.ma),
		ci: fallback(searchParams.get('ci'), defaultQueryParams.ci),
	}
}

export default function GenerateLabelToolPage() {
	const loaderData = useLoaderData()
	const [asset, setAsset] = useState(loaderData)
	const [otherAssetType, setOtherAssetType] = useState('')

	const updateData = useCallback(change => {
		const newData = append(asset, change)
		setAsset(newData)
		updateQueryParams({
			tp: typeof newData.tp === 'string' && newData.tp.length === 0 ? undefined : newData.tp,
			nm: typeof newData.nm === 'string' && newData.nm.length === 0 ? undefined : newData.nm,
			sn: typeof newData.sn === 'string' && newData.sn.length === 0 ? undefined : newData.sn,
			mn: typeof newData.mn === 'string' && newData.mn.length === 0 ? undefined : newData.mn,
			ip: typeof newData.ip === 'string' && newData.ip.length === 0 ? undefined : newData.ip,
			ma: typeof newData.ma === 'string' && newData.ma.length === 0 ? undefined : newData.ma,
			ci: typeof newData.ci === 'string' && newData.ci.length === 0 ? undefined : newData.ci,
		})
	}, [asset])

	const loadCIAbortController = useRef(null)
	const lastCIID = useRef('')
	const [loadingCI, setLoadingCI] = useState(false)
	const [configurationItem, setConfigurationItem] = useState(null)
	const [debouncedCIID, setDebouncedCIID] = useState(asset.ci.trim())
	const debouncedSetDebouncedCIID = useMemo(() => debounce(setDebouncedCIID, 500), [])
	useEffect(() => debouncedSetDebouncedCIID(asset.ci.trim()), [asset.ci, debouncedSetDebouncedCIID])
	useEffect(() => {
		// check if CI ID changed
		if (lastCIID.current !== debouncedCIID) {
			lastCIID.current = debouncedCIID

			// abort any in-progress CI loading
			if (loadCIAbortController.current !== null && !loadCIAbortController.current.signal.aborted) {
				loadCIAbortController.current.abort(new Error('Configuration Item ID changed'))
			}
			const currentAbortController = loadCIAbortController.current = new AbortController()

			// load CI
			if (!isObject(configurationItem) || configurationItem.id.toString() !== debouncedCIID.toString()) {
				setLoadingCI(true)
				getConfigurationItem(debouncedCIID, { signal: currentAbortController.signal })
					.then(res => {
						if (isObject(res) && isObject(res.data)) {
							setConfigurationItem(res.data)

							let changes = {}
							if (res.data.referenceTitle !== null) {
								changes.nm = res.data.referenceTitle
							}
							if (ASSET_TYPE_BY_CI_TYPE.hasOwnProperty(res.data.configurationItemType.value)) {
								changes.tp = ASSET_TYPE_BY_CI_TYPE[res.data.configurationItemType.value]
								setOtherAssetType('')
							} else {
								changes.tp = ASSET_TYPE_OTHER
								if (res.data.configurationItemType.label !== null) {
									setOtherAssetType(res.data.configurationItemType.label)
								} else {
									setOtherAssetType('')
								}
							}
							if (res.data.serialNumber !== null) {
								changes.sn = res.data.serialNumber
							}
							if (isObject(res.data.userDefinedFields) && res.data.userDefinedFields.hasOwnProperty('User-defined field 28') && res.data.userDefinedFields['User-defined field 28'] !== null) {
								changes.mn = res.data.userDefinedFields['User-defined field 28']
							}
							if (isObject(res.data.userDefinedFields) && res.data.userDefinedFields.hasOwnProperty('IP Address') && res.data.userDefinedFields['IP Address'] !== null) {
								changes.ip = res.data.userDefinedFields['IP Address']
							}
							if (isObject(res.data.userDefinedFields) && res.data.userDefinedFields.hasOwnProperty('MAC Address') && res.data.userDefinedFields['MAC Address'] !== null) {
								changes.ma = res.data.userDefinedFields['MAC Address']
							}
							updateData(changes)
						} else {
							setConfigurationItem(null)
						}
						setLoadingCI(false)
					})
					.catch(e => {
						if (!currentAbortController.signal.aborted) {
							console.error('Unable to load Configuration Item:', e)
							setConfigurationItem(null)
							setLoadingCI(false)
						}
					})
			} else {
				// indicate that we are no longer loading CI
				setLoadingCI(false)
			}
		} else if (lastCIID.current === debouncedCIID && isObject(configurationItem) && configurationItem.id.toString() === debouncedCIID.toString()) {
			// abort any in-progress CI loading
			if (loadCIAbortController.current !== null && !loadCIAbortController.current.signal.aborted) {
				loadCIAbortController.current.abort(new Error('Configuration Item ID changed'))
			}
			loadCIAbortController.current = new AbortController()

			// indicate that we are no longer loading CI
			setLoadingCI(false)
		}
	}, [debouncedCIID, asset, configurationItem, updateData])

	const {
		tp: assetType,
		nm: assetName,
		sn: assetSerialNumber,
		mn: assetModelNumber,
		ip: assetIPAddress,
		ma: assetMacAddress,
		ci: assetConfigurationItemID,
	} = asset

	return <>
		<Dash constrained>
			<style dangerouslySetInnerHTML={{__html: '@page { size: 70mm 40mm; margin: 0; } @media print { .kit-dash { display: none; } }'}} />
			<Segment vertical padded="very">
				<Container>
					<Header size="huge">Lag merkelapp</Header>
					<p>På denne siden kan du lage merkelapper til PCer. Bare trykk på feltene du vil redigere og skriv inn ny verdi.</p>
					<p>For å skrive ut merkelappen, trykk <kbd>Ctrl</kbd> + <kbd>P</kbd> eller trykk på knappen under.</p>
					<Message
						info
						icon="print"
						header="Velg riktig printer"
						content={
							<p>
								Husk å velge riktig printer når du skal printe ut lappen. Riktig printer hos Konsept-IT heter <b><code>KIT-PRINT6 (Etiketter)</code></b>
							</p>
						}
					/>

					<Grid stackable columns={2}>
						<Grid.Column width={9}>
							<Segment inverted color="orange" compact>
								<p>
									<Label as="a" ribbon="right" onClick={preventDefault(() => window.print())}><Icon name="print" className="left" /> Skriv ut</Label>
									<b style={{display: 'inline-block', marginLeft: '-7em'}}>Forhåndsvisning <code>(70 mm x 40 mm)</code></b>
								</p>
								<AssetLabel
									name={assetName}
									deviceType={assetType === ASSET_TYPE_OTHER ? otherAssetType : ASSET_TYPE_DISPLAY_NAME[assetType]}
									qrCodeValue={JSON.stringify(omitEmpty(asset))}
									onChange={change => updateData(change)}
								/>
							</Segment>
						</Grid.Column>

						<Grid.Column width={7}>
							<Form onSubmit={preventDefault()}>
								<Form.Input
									label="Enhetsnavn"
									value={assetName}
									onChange={(_, data) => updateData({ nm: data.value })}
								/>
								<Form.Select
									label="Enhetstype"
									placeholder={ASSET_TYPE_DISPLAY_NAME[ASSET_TYPE_OTHER]}
									options={Object.keys(ASSET_TYPE_DISPLAY_NAME).map(key => ({value: key, text: ASSET_TYPE_DISPLAY_NAME[key]}))}
									value={assetType}
									onChange={(_, data) => updateData({ tp: data.value })}
								/>
								{assetType === ASSET_TYPE_OTHER ? <Form.Input
									placeholder="Enhetstype"
									value={otherAssetType}
									onChange={(_, data) => setOtherAssetType(data.value)}
								/> : null}
								<p className="text-secondary">Usynlige felter som kun er inkludert i QR-koden:</p>
								<Form.Input
									label="Modellnummer"
									value={assetModelNumber}
									onChange={(_, data) => updateData({ mn: data.value })}
								/>
								<Form.Input
									label="Serienummer"
									value={assetSerialNumber}
									onChange={(_, data) => updateData({ sn: data.value })}
								/>
								<Form.Input
									label="IP-adresse"
									value={assetIPAddress}
									onChange={(_, data) => updateData({ ip: data.value })}
								/>
								<Form.Input
									label="MAC-adresse"
									value={assetMacAddress}
									onChange={(_, data) => updateData({ ma: data.value })}
								/>
								<Form.Input
									label="Configuration Item ID"
									value={assetConfigurationItemID}
									loading={loadingCI}
									onChange={(_, data) => updateData({ ci: data.value })}
								/>
							</Form>
						</Grid.Column>
					</Grid>
				</Container>
			</Segment>
		</Dash>
		<AssetLabel
			className="kit-print-only"
			name={assetName}
			deviceType={assetType === ASSET_TYPE_OTHER ? otherAssetType : ASSET_TYPE_DISPLAY_NAME[assetType]}
			qrCodeValue={JSON.stringify(omitEmpty(asset))}
		/>
	</>
}